import UtilityService from '../services/utility.service'
export default class BrandModel {
  constructor (businessId) {
    this.id = 0
    this.brand_id = 0
    this.brand_name = ''
    this.business_id = businessId
    this.created_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
  }

  load (data) {
    this.id = data.id
    this.brand_id = data.brand_id
    this.brand_name = data.brand_name
    this.business_id = data.business_id
    this.created_at = UtilityService.formatDateTime(
      data.created_at,
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      data.updated_at,
      'MM/DD/YY hh:mm A'
    )
    return this
  }
}
