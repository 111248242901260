<template>
    <div class="my-3 text-secondary">
        <span v-html="bulkModel.textOne"></span>
        <span v-html="bulkModel.textTwo"></span>
        <!-- Download link -->
        <button class="my-3 file-upload-browse btn btn-sm btn-outline-success btn-icon-text" @click="exportProductSampleExcel">
            <i class="zmdi zmdi-download btn-icon-prepend"></i>
            Download Excel template to import data
        </button>
        <span v-html="bulkModel.textThree"></span>
        <div class="form-group my-3">
            <label>File upload</label>
            <div class="input-group col-xs-12">
                <input @change="onFileUpload" type="file" class="form-control file-upload-info">
            </div>
        </div>
        <div>
            <button class="btn btn-primary mr-2" @click="onImportClick" :disabled="processingImport">
              <span v-show="processingImport" class="spinner-border spinner-border-sm"></span>
              {{ bulkModel.importButtonName }}
            </button>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */

import BulkModel from '../../models/BulkModel'
import XLSX from 'xlsx'
import _ from 'lodash'
import BusinessModel from '@/models/BusinessModel'
export default {
  name: 'BulkUpload',
  props: ['products', 'itemType', 'payload'],
  data () {
    return {
      file: null,
      jsonData: [],
      processingImport: false,
      publicPath: process.env.BASE_URL,
      bulkModel: new BulkModel(),
      xclProducts: []
    }
  },
  methods: {
    exportProductSampleExcel: function () {
      var product = this.products[0]
      var fields = {}
      var guarded = ['id', 'created_at', 'updated_at', 'district_id', 'division_id', 'brandChilds', 'serviceTypeChilds', 'status']
      if (this.products.length) {
        for (var property in product) {
          if (!guarded.includes(property)) {
            if (property !== 'properties') {
              fields[property] = ''
            }
            if (property === 'properties') {
              for (var index in product.properties) {
                if (product.properties[index].childs.length) {
                  for (var index2 in product.properties[index].childs) {
                    var prop = product.properties[index].childs[index2]
                    fields[prop.title] = ''
                  }
                }
              }
            }
          }
        }
        console.log(fields)
      }
      this.exportAsExcelNow('garirzone_excel', 'Sheet1', [fields])
    },
    onFileUpload (event) {
      this.file = event.target.files ? event.target.files[0] : null
      var reader = new FileReader()
      var vm = this
      reader.onload = function (e) {
        var data = new Uint8Array(e.target.result)
        var workbook = XLSX.read(data, { type: 'array' })
        var sheetName = workbook.SheetNames[0]
        var worksheet = workbook.Sheets[sheetName]
        vm.jsonData = XLSX.utils.sheet_to_json(worksheet)
        var modelProduct = JSON.parse(JSON.stringify(vm.products[0]))
        vm.xclProducts = []
        if (vm.itemType === 'business') {
          _.forEach(vm.jsonData, function (x) {
            var targetDistrict = vm.payload.districts.find(d => d.name.toLowerCase() === x.district.toLowerCase())
            var targetDivision = []
            x.division = x.division !== undefined ? x.division.trim() : ''
            if (!targetDistrict) {
              console.log('Wrong district!' + x.district)
              console.log(x)
              return
            }
            if (x.division) {
              targetDivision = vm.payload.divisions.find(d => d.name.toLowerCase() === x.division.toLowerCase())
            } else {
              targetDivision = vm.payload.divisions.find(d => d.id === targetDistrict.division_id)
            }
            if (!targetDivision) {
              console.log('Wrong division!' + x.division)
              console.log(x)
              return
            }
            var targetBrands = vm.payload.brands.filter(b => x.brand.toLowerCase().includes(b.brand_name.toLowerCase()))
            var targetServiceTypes = vm.payload.serviceTypes.filter(b => x.serviceType.toLowerCase().includes(b.service_name.toLowerCase()))
            var newBusiness = new BusinessModel()
            x.phone = x.phone ? x.phone.toString() : x.phone
            newBusiness.title = x.title
            newBusiness.phone = x.phone && x.phone[0] !== '0' && x.phone[0] >= '1' && x.phone[0] <= '9' ? '0' + x.phone : x.phone
            newBusiness.email = x.email
            newBusiness.address = x.address
            newBusiness.district = targetDistrict.name
            newBusiness.district_id = targetDistrict.id
            newBusiness.division = targetDivision.name
            newBusiness.division_id = targetDivision.id
            newBusiness.brandChilds = targetBrands
            newBusiness.serviceTypeChilds = targetServiceTypes
            vm.xclProducts.push(newBusiness)
          })
        } else {
          _.forEach(vm.jsonData, function (xclProduct) {
            var newProduct = JSON.parse(JSON.stringify(modelProduct))
            _.forEach(Object.keys(newProduct), function (rootPropKey) {
              if (rootPropKey === 'properties') {
                _.forEach(newProduct.properties, function (property) {
                  property.specification.value = xclProduct.hasOwnProperty(property.title) ? xclProduct[property.title] : ''
                  property.specification.value_bn = xclProduct.hasOwnProperty(property.title_bn) ? xclProduct[property.title_bn] : ''
                  _.forEach(property.childs, function (propertyChild) {
                    propertyChild.specification.value = xclProduct.hasOwnProperty(propertyChild.title) ? xclProduct[propertyChild.title] : ''
                    propertyChild.specification.value_bn = xclProduct.hasOwnProperty(propertyChild.title_bn) ? xclProduct[propertyChild.title_bn] : ''
                    _.forEach(propertyChild.childs, function (propertyChildsKid) {
                      propertyChildsKid.specification.value = xclProduct.hasOwnProperty(propertyChildsKid.title) ? xclProduct[propertyChildsKid.title] : ''
                      propertyChildsKid.specification.value_bn = xclProduct.hasOwnProperty(propertyChildsKid.title_bn) ? xclProduct[propertyChildsKid.title_bn] : ''
                    })
                  })
                })
              } else {
                newProduct[rootPropKey] = xclProduct.hasOwnProperty(rootPropKey) ? xclProduct[rootPropKey] : newProduct[rootPropKey]
              }
            })
            vm.xclProducts.push(newProduct)
          })
        }
      }
      reader.readAsArrayBuffer(this.file)
    },
    onImportClick () {
      if (this.jsonData.length > 0) {
        this.$emit('ExcelProductsUpload', this.xclProducts)
      } else {
        alert('No data found', 'danger')
      }
    },
    exportAsExcelNow (fileName, sheetName, jsonData) {
      var worksheet = XLSX.utils.json_to_sheet(jsonData)
      var workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName)
      XLSX.writeFile(workbook, fileName + this.getCurrentDate())
    },
    getCurrentDate () {
      var currentDate = new Date().toLocaleString().replace(', ', '_T').replace(' ', '')
      currentDate = currentDate.split('/')
      if (parseInt(currentDate[0]) < 10) {
        currentDate[0] = '0' + currentDate[0]
      }
      if (parseInt(currentDate[1]) < 10) {
        currentDate[1] = '0' + currentDate[1]
      }

      return '_' + currentDate.join('/') + '.xlsx'
    }
  }
}
</script>
