import UtilityService from '../services/utility.service'
import BrandModel from './BrandModel'
import ServiceTypeModel from './ServiceTypeModel'
import _ from 'lodash'

export default class BusinessModel {
  constructor () {
    this.id = 0
    this.title = ''
    this.phone = ''
    this.email = ''
    this.address = ''
    this.district = ''
    this.district_id = 0
    this.division = ''
    this.division_id = 0
    this.brand = new BrandModel(0)
    this.serviceType = new ServiceTypeModel(0)
    this.brandChilds = []
    this.serviceTypeChilds = []
    this.status = 1
    this.created_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      new Date(),
      'MM/DD/YY hh:mm A'
    )
  }

  load (data) {
    this.id = data.id
    this.title = data.title
    this.phone = data.phone
    this.email = data.email
    this.address = data.address
    this.district = data.district
    this.district_id = data.district_id
    this.division = data.division
    this.division_id = data.division_id
    this.status = data.status
    if (data.get_brands) {
      this.brandChilds = _.map(data.get_brands, function (c) {
        return new BrandModel().load(c)
      })
    }
    if (data.get_service_types) {
      this.serviceTypeChilds = _.map(data.get_service_types, function (c) {
        return new ServiceTypeModel().load(c)
      })
    }
    this.created_at = UtilityService.formatDateTime(
      data.created_at,
      'MM/DD/YY hh:mm A'
    )
    this.updated_at = UtilityService.formatDateTime(
      data.updated_at,
      'MM/DD/YY hh:mm A'
    )
    return this
  }
}
