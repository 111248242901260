export default class BulkModel {
  constructor () {
    this.fileName = 'products.xlsx'
    this.importButtonName = 'Import Products'
    this.textOne = '<p>' + '</p>'

    this.textTwo = '<p></p>'

    this.textThree = '<p>' + '</p>'
  }
}
